




















































import DatePicker from '@/components/inputs/DatePicker.vue';
import { useCreatingBooking } from '@/composables/useCreatingBooking';
import { Schedule } from '@/models/booked/Schedule';
import { defineComponent, PropType } from '@/plugins/composition';
import { formatDate, formatTime } from '@/utils/date';
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core';
import LegendDialog from './LegendDialog.vue';
import SettingsDrawer from './SettingsDrawer.vue';
import { routeNames } from '@/router/routeNames';

export default defineComponent({
  components: { DatePicker, SettingsDrawer, LegendDialog },
  name: 'ScheduleCalendarPageHeader',
  props: {
    schedule: {
      type: Object as PropType<Schedule>,
    },
  },
  setup() {
    const { selected, showSettings } = useCreatingBooking();
    const { md } = useBreakpoints(breakpointsTailwind);
    return {
      formatTime,
      formatDate,
      selected,
      showSettings,
      md,
      routeNames,
    };
  },
});
