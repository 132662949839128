








































































import { useBookedSchedules } from '@/composables/useBookedResources/useBookedSchedules';
import { useLoader } from '@/composables/useLoader';
import { Resource } from '@/models/booked/Resource';
import { Schedule } from '@/models/booked/Schedule';
import { Slot } from '@/models/booked/Slot';
import { computed, defineComponent, onMounted, PropType, ref, watch } from '@/plugins/composition';
import { endOfWeek } from 'date-fns';
import ResourceCalendar from './ResourceCalendar.vue';
import SlotCalendarRow from './SlotCalendarRow.vue';
import { isSameDay } from 'date-fns';
import {
  useCreatingBooking,
  calendarSelection,
  hasAvailableSlots,
  hasConflicts,
} from '@/composables/useCreatingBooking';
import { routeNames } from '@/router/routeNames';
import { useSettingsDrawer } from '@/composables/useCreatingBooking/useSettingsDrawer';
import { useStatus } from '@/composables/useStatus';
import { useAuthStore } from '@/composables/useAuthStore';
import { PendingReservationResponse } from '@/composables/useBookedResources/types';

export default defineComponent({
  components: { SlotCalendarRow, ResourceCalendar },
  name: 'ScheduleCalendar',
  props: {
    schedule: {
      type: Object as PropType<Schedule | null>,
      required: true
    },
    start: {
      type: Date,
      default: () => new Date(),
    },
    guest: {
      type: Boolean,
      default: false,
    },
    selectedSlot: {
      type: Object as PropType<Slot>,
      required: false
    },
    reservation: {
      type: Object as PropType<PendingReservationResponse>,
        required:false
    }
  },
  setup(props, { emit }) {
    const { getScheduleSlots, getScheduleRepeatingSlots } = useBookedSchedules();
    const { setMessage } = useStatus();
    const { isMember } = useAuthStore();

    const resources = ref<Resource[]>([]);

    const availableResources = computed(() => {
      return resources.value.filter((resource) => {
        return hasAvailableSlots(resource) && !hasConflicts(resource);
      });
    });

    const unavailableResources = computed(() => {
      return resources.value.filter((resource) => {
        return hasConflicts(resource);
      });
    });

    const { currentResource, currentSchedule, isRepeating, repeatingOptions, showSettings } = useCreatingBooking();
    const { refreshRequired } = useSettingsDrawer();
    const { loading, withLoader } = useLoader();
    const editSelectedSlot = ref<Slot>();

    const load = async () =>
      withLoader(async () => {
        if (props.schedule) {
          const schedule = isRepeating.value
            ? await getScheduleRepeatingSlots(props.schedule.id, repeatingOptions.value)
            : await getScheduleSlots(props.schedule.id, {
                start: props.start,
                end: endOfWeek(props.start, { weekStartsOn: 1 })
              },
              props.reservation?.id);

          resources.value = schedule.resources;
        }
      });

    const selectSlot = async (slot: Slot, selectedResource: Resource) => {
      if (props.guest) {
        if (!isMember.value) {
          setMessage('This calendar is view only. Get an Artist Pass to make a booking.');
        } else {
          setMessage('This calendar is view only. Login to make a booking.');
        }

        return;
      }

      currentResource.value = selectedResource;

      if (calendarSelection.startSlot) {
        const start = new Date(calendarSelection.startSlot.startDateTime);
        const selected = new Date(slot.startDateTime);

        if (!isSameDay(start, selected)) {
          calendarSelection.startSlot = slot;
          calendarSelection.endSlot = slot;
          return;
        }
      }

      if (calendarSelection.startSlot) {
        const start = new Date(calendarSelection.startSlot.startDateTime);
        const selected = new Date(slot.startDateTime);

        if (selected.getTime() < start.getTime()) {
          calendarSelection.startSlot = slot;
          calendarSelection.endSlot = slot;
          return;
        }
      }

      if (calendarSelection.startSlot && calendarSelection.endSlot) {
        const start = new Date(calendarSelection.startSlot.startDateTime);
        const end = new Date(calendarSelection.endSlot.endDateTime);

        const selected = new Date(slot.startDateTime);

        if (selected.getTime() >= start.getTime() && selected.getTime() < end.getTime()) {
          calendarSelection.startSlot = slot;
          calendarSelection.endSlot = slot;
          return;
        }
      }

      if (calendarSelection.startSlot) {
        calendarSelection.endSlot = slot;
        return;
      } else {
        calendarSelection.startSlot = slot;
        calendarSelection.endSlot = slot;
        return;
      }
    };

    onMounted(async () => {
      editSelectedSlot.value = props.selectedSlot;
      await load();
      currentSchedule.value = props.schedule;
    });

    watch(
      () => props.schedule,
      async () => await load()
    );

    watch(
      () => props.start,
      async () => {
        if (!isRepeating.value) {
          await load();
        }
      }
    );

    watch(refreshRequired, async (val: boolean) => {
      if (refreshRequired.value) {
        await load();
        refreshRequired.value = false;
      }
    });

    return {
      resources,
      loading,
      emit,
      selectSlot,
      routeNames,
      availableResources,
      unavailableResources,
      isRepeating,
      editSelectedSlot
    };
  },
});
