


















































































import BaseButton from '@/components/base/BaseButton.vue';
import DatePicker from '@/components/inputs/DatePicker.vue';
import ScheduleSelectInput from '@/components/inputs/ScheduleSelect/ScheduleSelectInput.vue';
import ScheduleCalendar from '@/components/SlotCalendar/ScheduleCalendar.vue';
import { useAuthStore } from '@/composables/useAuthStore';
import MemberPage from '@/layouts/MemberLayout/MemberPage.vue';
import { Schedule } from '@/models/booked/Schedule';
import { defineComponent, ref, onMounted, watch } from '@/plugins/composition';
import { routeNames } from '@/router/routeNames';
import { useRouter } from '@/router/useRouter';
import InlineLegend from '../BookSpace/ScheduleCalendar/components/InlineLegend.vue';
import HomePageText from './components/HomePageText.vue';
import ScheduleCalendarConfirmationDrawer from '../BookSpace/ScheduleCalendar/components/ScheduleCalendarConfirmationDrawer.vue';
import ScheduleCalendarPageHeader from '../BookSpace/ScheduleCalendar/components/ScheduleCalendarPageHeader.vue';
import SettingsDrawer from '../BookSpace/ScheduleCalendar/components/SettingsDrawer.vue';
//import { Schedule } from '@/models/booked/Schedule';
import { useCreatingBooking, currentBooking } from '@/composables/useCreatingBooking';
import { useBookedSchedules } from '@/composables/useBookedResources';
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core';
import { formatDate, formatTime } from '@/utils/date';
import { useLoader } from '@/composables/useLoader';

export default defineComponent({
  components: 
  { 
    ScheduleSelectInput, HomePageText, BaseButton, DatePicker, ScheduleCalendar, MemberPage, 
    ScheduleCalendarConfirmationDrawer, InlineLegend, ScheduleCalendarPageHeader, SettingsDrawer
  },
  name: 'HomePage',
  setup() {
    const startDate = ref(new Date());
    const mSchedule = ref<Schedule | null>(null);
    const schedule = ref<Schedule | null>(null);
    const { isAuthed } = useAuthStore();

    const repeats = ref(false);

    //const settings = ref(null);

    const { refreshState } = useAuthStore();

    const { router } = useRouter();

    const { loading, withLoader } = useLoader({ initial: true });

    const { selected, showSettings, clearSlots } = useCreatingBooking();
    const { getSchedule } = useBookedSchedules();
    const { md } = useBreakpoints(breakpointsTailwind);

    watch(mSchedule, async () => {
      withLoader(async () => {
        schedule.value = await getSchedule(mSchedule.value ? mSchedule.value.id : 1);
      });
    });

    onMounted(async () => {
      if(isAuthed) {
        withLoader(async () => {
          await refreshState();
          schedule.value = await getSchedule(mSchedule.value ? mSchedule.value.id : 1);
          console.log('------- schedule', schedule);
        });
      }
      
    });

    const goToLogin = () => {
      router.push({ name: routeNames.auth.login });
    };

    return {
      startDate,
      mSchedule,
      schedule,
      goToLogin,
      isAuthed,
      clearSlots,
      selected,
      currentBooking,
      repeats,
      showSettings,
      formatTime,
      formatDate,
      loading
    };
  },
});
