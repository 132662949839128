
































import { computed, defineComponent, onMounted, ref } from '@/plugins/composition';
import { formatDate } from '@/utils/date';
import { parse, parseISO, startOfWeek } from 'date-fns';
import { getDay } from 'date-fns/esm';

export default defineComponent({
  name: 'DatePicker',
  props: {
    value: {
      type: Date,
      default: () => new Date(),
    },
    label: {
      type: String,
      default: 'Select a Date',
    },
    weekly: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Date,
    },
  },
  setup(props, { emit }) {
    const menu = ref(false);
    const date = computed({
      get: () => {
        return formatDate(props.value, 'yyyy-MM-dd') as string;
      },
      set: (value: string) => {
        emit('input', parseISO(value));
      },
    });

    onMounted(() => {
      if (props.weekly) {
        date.value = formatDate(startOfWeek(props.value, { weekStartsOn: 1 }), 'yyyy-MM-dd') as string;
      }
    });

    const setDate = (string: string) => {
      date.value = string;
    };

    const allowedDates = (val: string) => {
      if (!props.weekly) {
        return true;
      }

      const date = parse(val, 'yyyy-MM-dd', new Date());

      return getDay(date) === 1;
    };

    const minDate = computed(() => {
      if (!props.min) {
        return null;
      }

      return formatDate(props.min, 'yyyy-MM-dd');
    });

    return {
      menu,
      date,
      formatDate,
      setDate,
      allowedDates,
      minDate,
    };
  },
});
