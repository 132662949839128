



































import BaseButton from '@/components/base/BaseButton.vue';
import { useCreatingBooking, calendarSelection } from '@/composables/useCreatingBooking';
import { defineComponent } from '@/plugins/composition';
import { routeNames } from '@/router/routeNames';
import { useRouter } from '@/router/useRouter';
import { formatDate } from '@/utils/date';

export default defineComponent({
  components: { BaseButton },
  name: 'ScheduleCalendarConfirmationDrawer',
  setup() {
    const { selected, clearSlots, isRepeating, showConfirmation, currentResource } = useCreatingBooking();
    const { router } = useRouter();

    const addBooking = () => {
      router.push({ name: routeNames.bookings.confirm });
    };

    return {
      selected,
      clearSlots,
      isRepeating,
      formatDate,
      calendarSelection,
      addBooking,
      showConfirmation,
      currentResource,
    };
  },
});
