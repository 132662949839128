





































































































































import DatePicker from '@/components/inputs/DatePicker.vue';
import { useCreatingBooking, currentBooking } from '@/composables/useCreatingBooking';
import { computed, defineComponent } from '@/plugins/composition';
import { formatDate } from '@/utils/date';
import { format } from 'date-fns';
import RecurringInput from '../../components/RecurringInput.vue';
import { useSettingsDrawer } from '@/composables/useCreatingBooking/useSettingsDrawer';
import startOfWeek from 'date-fns/startOfWeek/index';

export default defineComponent({
  name: 'SettingsDrawer',
  components: {
    RecurringInput,
    DatePicker,
  },
  setup() {
    const { showSettings, repeatingType, selectedDays, isRepeating } = useCreatingBooking();
    const { refreshRequired } = useSettingsDrawer();

    const confirm = () => {
      showSettings.value = false;
      refreshRequired.value = true;
    };

    const minStart = computed(() => {
      if (!isRepeating.value) {
        return startOfWeek(new Date(), { weekStartsOn: 1 });
      }

      return new Date();
    });

    const minEnd = computed(() => {
      return minStart.value;
    });

    return {
      isRepeating,
      ...useSettingsDrawer(),
      selectedDays,
      showSettings,
      formatDate,
      formatTime: format,
      repeatingType,
      currentBooking,
      confirm,
      minStart,
      minEnd,
    };
  },
});
