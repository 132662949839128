

























































import BaseButton from '@/components/base/BaseButton.vue';
import { defineComponent, ref } from '@/plugins/composition';

export default defineComponent({
  components: { BaseButton },
  name: 'LegendDialog',
  setup() {
    const dialog = ref(false);

    const colors = [
      {
        color: 'black',
        text: 'Closed',
      },
      {
        color: 'lightYellow',
        text: 'Available',
        icon: '+',
      },
      {
        color: 'darkGray',
        text: 'Booked',
      },
      {
        color: 'lightYellow darken-1',
        text: 'Your Selection',
      },
      {
        color: 'error',
        text: 'Unavailable',
      },
    ];

    return {
      dialog,
      colors,
    };
  },
});
