






















import BaseButton from '@/components/base/BaseButton.vue';
import { computed, defineComponent, ref } from '@/plugins/composition';
import LegendDialog from './LegendDialog.vue';

export default defineComponent({
  components: { BaseButton, LegendDialog },
  name: 'InlineLegend',
  props: {
    guest: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const dialog = ref(false);

    const colorsToShow = computed(() => {
      return colors.filter((color) => {
        if (props.guest) {
          return color.guest === undefined ? true : color.guest;
        }

        return color.guestOnly === undefined ? true : !color.guestOnly;
      });
    });

    const colors = [
      {
        color: 'lightYellow',
        text: 'Available',
        icon: '+',
      },
      {
        color: 'black',
        text: 'Closed',
        guestOnly: true,
      },
      {
        color: 'darkGray',
        text: 'Booked',
      },
      {
        color: 'lightYellow darken-1',
        text: 'Your Selection',
        guest: false,
      },
      // {
      //   color: 'error',
      //   text: 'Unavailable',
      // },
    ];

    return {
      dialog,
      colorsToShow,
    };
  },
});
